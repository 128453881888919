/* global $ */
$(function () {
  const Repository = function () {
    let _$container
    let _$addResourceBtn
    let _$resources
    let _$clonedResource
    let _$elementIndex
    const FILE_SRC_EXAMPLE = 'repo/content/file_en.md'
    const FILE_DEST_EXAMPLE = 'repo/content/file_%lang%.md'
    const DIR_SRC_EXAMPLE = 'repo/content/en'
    const DIR_DEST_EXAMPLE = 'repo/content/%lang%'

    initialize.apply(this, arguments)

    function initialize ($container) {
      _$container = $container
      _$resources = _$container.find('.js-resource-wrapper')
      _$resources.find('.js-resource-remove-btn').click(function () {
        _onRemoveResource(this)
      })
      _$resources.find('.js-resource-type-select').change(function () {
        _onChangeSelect(this)
      })
      $container.find('input[type=radio]').each(() => { this.addEventListener('change', changePathType) })
      _$addResourceBtn = _$container.find('.js-resource-add-btn')

      _$addResourceBtn.off('click', _onAddResource)
      _$addResourceBtn.click(_onAddResource)
    }

    function _onAddResource () {
      _$clonedResource = _getClonedResource()
      _$clonedResource.find('.disabled-path-radiobuttons').removeClass('disabled-path-radiobuttons')
      _$clonedResource.addClass('js-resource-can-delete')
      _$clonedResource.find('.js-resource-remove-btn').click(function () {
        _onRemoveResource(this)
      })
      _$clonedResource.find('.js-resource-type-select').change(function () {
        _onChangeSelect(this)
      })
      upResourceIndex(_$clonedResource)

      _$clonedResource.find('input[type=radio]').each(() => { this.addEventListener('change', changePathType) })
      _$container.append(_$clonedResource)
      $('[data-toggle=tooltip]').tooltip()
    }

    function _onRemoveResource (resourceRemoveBtn) {
      const $resourceRemoveBtn = $(resourceRemoveBtn)
      const $resource = $resourceRemoveBtn.closest('.js-resource-wrapper')
      if ($resource.hasClass('js-resource-can-delete')) {
        $resource.remove()
      }
    }

    function _onChangeSelect (resourceSelect) {
      const $resourceSelect = $(resourceSelect)
      const $resource = $resourceSelect.closest('.js-resource-wrapper')
    }

    function _getClonedResource () {
      const $clonedResource = _$resources.last().clone()
      $clonedResource.find('input[type!=radio]').val('')
      $clonedResource.find('.repository-resource-dest input').attr('data-valid-input', true)
      $clonedResource.find('.js_path-rule-alert').attr('data-valid-input', true)
      return $clonedResource
    }

    function changePathType (e) {
      const input = e.target
      const resource = input.parentElement.parentElement.parentElement
      let srcExample = null
      let destExample = null
      const src_input = $(resource).find('.source-path')
      const dest_input = $(resource).find('.dest-path')

      if (input.value === 'file') {
        srcExample = FILE_SRC_EXAMPLE
        destExample = FILE_DEST_EXAMPLE
      } else if (input.value === 'dir') {
        srcExample = DIR_SRC_EXAMPLE
        destExample = DIR_DEST_EXAMPLE
      }

      if (srcExample === null) return 0

      src_input.attr('placeholder', 'e.g. ' + srcExample)
      src_input.val('')

      dest_input.attr('placeholder', 'e.g. ' + destExample)
      dest_input.val('')

      changeTooltips(resource)
    }

    function changeTooltips (resource) {
      const $source = $(resource).find('[date-behavior~=source_tooltip]')
      const $dest = $(resource).find('[date-behavior~=dest_tooltip]')
      let current_title

      current_title = $source.attr('data-original-title')
      $source.attr('data-original-title', $source.attr('changed-title'))
      $source.attr('changed-title', current_title)

      current_title = $dest.attr('data-original-title')
      $dest.attr('data-original-title', $dest.attr('changed-title'))
      $dest.attr('changed-title', current_title)
    }

    function upResourceIndex (resource) {
      const srcPathElement = resource.find('.source-path')
      const destPathElement = resource.find('.dest-path')
      const typeElements = resource.find('input[type=radio]')
      if (_$elementIndex) {
        _$elementIndex += 1
      } else {
        _$elementIndex = parseInt(srcPathElement.attr('name').match(/repository\[path_rules\]\[(\d+)\]\[src\]/)[1]) + 1
      }
      srcPathElement.attr('name', srcPathElement.attr('name').replace(
        /repository\[path_rules\]\[(\d+)\]\[src\]/, `repository[path_rules][${_$elementIndex}][src]`
      ))
      destPathElement.attr('name', destPathElement.attr('name').replace(
        /repository\[path_rules\]\[(\d+)\]\[dest\]/, `repository[path_rules][${_$elementIndex}][dest]`
      ))
      typeElements.toArray().forEach((input) => {
        input.setAttribute('name', input.getAttribute('name').replace(
          /repository\[path_rules\]\[(\d+)\]\[type\]/, `repository[path_rules][${_$elementIndex}][type]`
        ))
      })
    }
  }

  Repository($('.js-resource-container'))
  $('.submit-path-rules').on('click', function () {
    $('.input.-dest-path').each(function (_arg, input) {
      const validInput = input.value.split('/').find(function (path) {
        return path.match(/%lang%/)
      })
      const $alert = $('.js_path-rule-alert')
      $alert.data('valid-input', !!validInput)
      $(input).data('valid-input', !!validInput)
    })
    return $('.input.-dest-path[data-valid-input="false"]').length === 0
  })

  $('#js-submit').on('click', function (e) {
    activateSubmit(this)
    e.preventDefault()
    $('.input.-dest-path').each(function (_arg, input) {
      const validInput = input.value.split('/').find(function (path) {
        return path.match(/%lang%/)
      })
      const $alert = $('.js_path-rule-alert')
      $alert.data('valid-input', !!validInput)
      $(input).data('valid-input', !!validInput)
    })

    const pathRules = []

    $('.repository-resource').each(function (index) {
      const rule = {}
      rule.src = cutPath($(this).find('.js-resource-src').val())
      rule.dest = cutPath($(this).find('.js-resource-dest').val())
      pathRules.push(rule)
    })

    const data = {
      repository: {
        repo_info: $('#repo-name').val(),
        branch: $('#branch').val(),
        path_rules: JSON.stringify(pathRules),
        language_code: $('.js-lang-selector').val()
      },
      target_language_codes: prepareTargetLanguages()
    }

    $.ajax({
      url: '/repo.json',
      data: data,
      method: 'post',
      success: (data) => {
        if (data.failed === true) {
          $('#popup-too-many-requests').modal('show')
        }
        if (data.errors) {
          removeFlashError()
          showFlashError(data.errors)
          $('html, body').animate({ scrollTop: 0 }, 'fast')
        }
        if (data.redirect_path) {
          window.location = data.redirect_path
        }
        activateSubmit(this)
      }
    })
    return $('.input.-dest-path[data-valid-input="false"]').length === 0
  })

  function cutPath (path) {
    const selectedRepoName = $('[data-behavior~=repository-name-select] option:selected').text()
    if (path.match('^' + selectedRepoName + '/')) {
      path = path.substring(path.search('/'))
    }
    return path
  }

  function activateSubmit (submit) {
    const spinner = submit.parentElement.getElementsByClassName('spinner_for_submit')[0]
    submit.disabled = !submit.disabled
    spinner.style.display = (spinner.style.display === 'block' ? '' : 'block')
  }

  function prepareTargetLanguages () {
    const languages = []
    $('[data-behavior~=target-languages-tbody]').find('tr').toArray().forEach((tr) => {
      const $tr = $(tr)
      const languageCode = $tr.attr('title').match(/\w+ \[(\S+)\]/)[1]
      const customLanguageCode = $tr.find('input').val()
      const languageModerators = $tr.find('.js_user-search-input').val()
      languages.push(
        {
          language_code: languageCode,
          custom_langauge_code: languageCode === customLanguageCode ? null : customLanguageCode,
          language_moderators: languageModerators
        }
      )
    })

    return languages
  }
})
