import 'core-js/stable'
import 'regenerator-runtime/runtime'

require('jquery')
require("@rails/ujs").start()
require('popper.js')
require('js-cookie')
require('select2')
require('js.cookie')
require('bootstrap')

require('./nameplate')
require('./components/add_user_role')
require('./components/commit')
require('./components/advice_cla')
require('./components/select_user')
require('./repositories/new')
require('./repositories/setting')
require('./repositories/download_tmx')
require('./repositories/reload_resource')
require('./repositories/resources/show_file')
require('./repositories/review_requests/index')
require('./repositories/badge')
require('./reload')
require('./services')

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
