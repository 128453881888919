function createNameplate(statistic) {
  const { total_chars, translated_chars, untranslated_chars, approved_chars, unapproved_chars,
          total_words, translated_words, untranslated_words, approved_words, unapproved_words } = statistic;
  var nameplate = document.createElement('div');
  nameplate.style.display = 'none';

  var table = document.createElement('div');
  table.classList.add('table');

  var total = 'Total';
  var translated = 'Translated';
  var untranslated = 'Untranslated';
  var approved = 'Approved';
  var unapproved = 'Unapproved';
  createRow(table, `${total}: `, total_chars, total_words)
  createRow(table, `${untranslated}: `, untranslated_chars, untranslated_words)
  createRow(table, `${translated}: `, translated_chars, translated_words)
  createRow(table, `${unapproved}: `, unapproved_chars, unapproved_words)
  createRow(table, `${approved}: `, approved_chars, approved_words)

  var footer = document.createElement('div');
  footer.classList.add('arrow-down');

  nameplate.appendChild(table)
  nameplate.appendChild(footer)

  return nameplate;
}

function createRow(table, key, chars, words) {
  if (chars == null || words == null)
    return

  var row = document.createElement('div');
  row.classList.add('row');

  row.appendChild(createCol(key));
  row.appendChild(createCol(
    `${chars} ${'chars'} | ${words} ${'words'}`
  ));

  table.appendChild(row);
}

function createCol(text) {
  var col = document.createElement('div');
  col.classList.add('col');
  col.innerHTML += text;

  return col;
}

function closeOtherNameplates() {
  $('.nameplate').each((i, elem) => {
    elem.style.display = 'none';
  })
}

function onClickEvent(event) {
  var current = event.currentTarget;
  for (let child of current.children) {
    if(child.className.includes('nameplate')) {
      if (child.style.display === 'none') {
        closeOtherNameplates();
        child.style.display = 'flex';
      } else {
        child.style.display = 'none';
      }
    }
  }
}

export function renderNameplate(statistic) {
  var current = $('[data-behavior~=language_characters_result]')[0];

  var nameplate = createNameplate(statistic);

  nameplate.classList.add('nameplate');

  current.appendChild(nameplate);
}

export function addNameplate(current, statistic) {
  var nameplate = createNameplate(statistic);

  nameplate.classList.add('nameplate');

  current.appendChild(nameplate);
  current.addEventListener('click', onClickEvent);
}
