$(() => {
  $('#reload-resource').on('click', function(){
    $.ajax({
      url: '/api/resources/' + $('#reload-resource-info').data('id') + '/' + $('#reload-resource-info').data('code') + '/reload',
      success: (data) => {
        var host = window.location.origin
        window.location.replace(host + data.url)
      }
    })
  })
});

