/* global $ window Stripe */
var Vendor = function () { // eslint-disable-line no-unused-vars
  var _$container
  var _stripe
  var _stripeCard

  var _$currentVendor

  // vendor
  var _$vendorList
  var _$vendorItem

  // target resources
  var _$targetResource
  var _$targetResourceRows
  var _$targetResourceCheckboxes
  var _$targetResourceSelects

  // order resources
  var _$orderResource
  var _$orderResourceDefaultRow
  var _$orderResourceRows
  var _$orderResourceApproximatePrice
  var _$orderResourceSubmitBtn
  var _$submitBtn
  var _$jsLoader
  var _$modal
  var _$orderResourceErrorMessageContainer

  var _$modalErrorMessageContainer
  var _$modalErrorMessage

  var _$buttonForIntercom

  initialize.apply(this, arguments)

  function initialize ($container) {
    // if ($container.length === 0) return false
    _$container = $container

    // vendor
    // _$vendorList = _$container.find('.js_vendor-list')
    // _$vendorItem = _$vendorList.find('.js_vendor-item')

    // target
    // _$targetResource = _$container.find('.js_vendor-resource')
    // _$targetResourceRows = _$targetResource.find('.js_vendor-target-resource-row')
    // _$targetResourceCheckboxes = _$targetResource.find('.js_vendor-target-resource-checkbox')
    // _$targetResourceSelects = _$targetResource.find('.js_vendor-target-resource-select')

    // order
    // _$orderResource = _$container.find('.js_vendor-order-resource')
    // _$orderResourceDefaultRow = _$orderResource.find('.js_vendor-order-resource-row-default')
    // _$orderResourceRows = _$orderResource.find('.js_vendor-order-resource-row')
    // _$orderResourceApproximatePrice = _$orderResource.find('.js_vendor-order-resource-approximate-price')
    // _$jsLoader = _$container.find('.js_loader')
    // _$modal = _$container.find('#confirmModal')

    // _$orderResourceErrorMessageContainer = _$container.find('.js_vendor-order-error-message-container')

    // _$orderResourceSubmitBtn = _$orderResource.find('.js_vendor-order-resource-submit-btn')
    // _$submitBtn = _$orderResource.find('.js_vendor-submit-btn')
    // _$modalErrorMessageContainer = _$container.find('.js_modal-error-container')
    // _$modalErrorMessage = _$container.find('.js_modal-error-message')

    _$buttonForIntercom = $('.js-request-a-quote')
    _$buttonForIntercom.on('click', function () { _onRequestQuoteClick() })

    // if ($('#card-element').length > 0) {
    //   _buildStripeElement()
    // }

    // Call event to place an order
    // _$submitBtn.off('click', _onClickOrderResourceSubmitBtn)
    // _$submitBtn.click(_onClickOrderResourceSubmitBtn)

    // _$targetResourceCheckboxes.change(function () { _onUserCheckTgtLc(this) })
    // _$targetResourceSelects.change(function () { _onUserChangeTgtQuality(this) })

    // hide error message component for modal
    // _$modalErrorMessageContainer.hide()
    // hide jsLoader
    // _$jsLoader.hide()
    // hide message
    // _$orderResourceErrorMessageContainer.hide()
    // disable tgt checkbox
    // _$targetResourceRows.find('input[type=checkbox]').attr('disabled', true)
    // disable tgt select
    // _$targetResourceRows.find('select').attr('disabled', true)
    // disable "Confirm an order" button
    // _$orderResourceSubmitBtn.attr('disabled', true)
    // disable "Place an order" button
    // _$submitBtn.attr('disabled', true)

    // _$currentVendor = _$vendorItem.first()
    // _$currentVendor.addClass('vendor-item-selected')
    // $.each(_$orderResourceRows, function () {
    //   var index = $(this).data('id')
    //   var name = _$currentVendor.find('h3').text()
    //   _setVendorName(index, name)
    // })

    // _getAndSetupTgt(_$container.data('repository-id'), _$container.data('source-language-code'))
  }

  function _getAndSetupTgt (repositoryId, lc) {
    $.ajax({
      url: '/api/repo/' + repositoryId + '/' + lc + '/resources',
      success: _filterResource
    })
  }

  function _onRequestQuoteClick () {
    window.Intercom('showNewMessage', $('.info-for-intercom').val())
    woopra.track('quota_requested')

    $.ajax({
      url: '/api/need_professional_translation',
      data: {
        place: 'vendors_request',
        repository_id: _$buttonForIntercom.data('repository-id')
      },
      method: 'post',
      dataType: 'json'
    })
  }

  function _filterResource (languageCompletions) {
    $.each(_$targetResourceRows, function (index, targetResourceRow) {
      var $targetResourceRow = $(targetResourceRow)
      var languageCode = $targetResourceRow.data('language-code')
      $.each(languageCompletions, function (index, languageCompletion) {
        if (languageCode === languageCompletion.language_code) { //
          // Enable checkbox if a vendor supports language
          var $checkbox = $targetResourceRow.find('.js_vendor-target-resource-checkbox')
          if (languageCompletion.supported) {
            $checkbox.attr('disabled', false)
          } else {
            var $td = $checkbox.closest('td')
            $checkbox.remove()
            $td.text('not supported.')
          }

          // Add quality option
          var $select = $targetResourceRow.find('.js_vendor-target-resource-select')
          var quality = languageCompletion.quality
          if (languageCompletion.supported) {
            $select.find('option[value=initial]').remove()
            $select.attr('disabled', false)
            if (quality.length > 0) {
              for (var i = 0; i < quality.length; i++) {
                $targetResourceRow.attr('data-' + quality[i]['name'] + '-unit-price', quality[i]['price'])
                $select.append($('<option></option>').attr('value', quality[i]['name']).text(quality[i]['name']))
              }
            }
          } else {
            $select.find('option[value=initial]').text('not supported.')
          }
        }
      })
    })
  }

  function _checkAndUpdateOrderStatus () {
    var $orderRows = $('.js_vendor-order-resource-row[data-disabled=false]')
    var minimumPrice = 1.00
    var shouldShowDefaultRow = $orderRows.length > 0
    var isInvalidApproximatePrice = (+_$orderResourceApproximatePrice.attr('data-value') <= minimumPrice && +_$orderResourceApproximatePrice.attr('data-value') !== 0)

    shouldShowDefaultRow ? _$orderResourceDefaultRow.hide() : _$orderResourceDefaultRow.show()
    _updateSubmitButtonState(isInvalidApproximatePrice)
  }

  function _updateSubmitButtonState (isInvalidApproximatePrice) {
    if (isInvalidApproximatePrice) {
      _$orderResourceApproximatePrice.addClass('-error')
      _$orderResourceErrorMessageContainer.addClass('-error')
      _$orderResourceErrorMessageContainer.show()
      _$orderResourceSubmitBtn.attr('disabled', true)
      _$submitBtn.attr('disabled', true)
    } else {
      _$orderResourceApproximatePrice.removeClass('-error')
      _$orderResourceErrorMessageContainer.removeClass('-error')
      _$orderResourceErrorMessageContainer.hide()
      _$orderResourceSubmitBtn.attr('disabled', false)
      _$submitBtn.attr('disabled', false)
    }
  }

  function _onUserCheckTgtLc (evt) {
    _$orderResourceSubmitBtn.attr('disabled', true)

    var $checkbox = $(evt)
    var $targetResourceRow = $checkbox.closest('.js_vendor-target-resource-row')
    var index = $targetResourceRow.data('id')
    var $vendorOrderRow = $('.js_vendor-order-resource-row[data-id=' + index + ']')
    var selectedOption = $targetResourceRow.find('select option:selected').text()

    if ($checkbox.prop('checked')) {
      $vendorOrderRow.attr('data-disabled', false)
      _setVendorName(index, _$currentVendor.find('h3').text())
      _setQuality(index, selectedOption)
      _setUnitPrice(index, $targetResourceRow.data(selectedOption + '-unit-price'))
      _updatePrices(index, _$container.data('repository-id'), $targetResourceRow.data('language-code'))
      $vendorOrderRow.show()
    } else {
      $vendorOrderRow.attr('data-disabled', true)
      _setApproximatePrice()
      _checkAndUpdateOrderStatus()
      $vendorOrderRow.hide()
    }
  }

  function _onUserChangeTgtQuality (evt) {
    _$orderResourceSubmitBtn.attr('disabled', true)

    var $select = $(evt)
    var $targetResourceRow = $select.closest('.js_vendor-target-resource-row')
    var index = $targetResourceRow.data('id')
    var selectedOption = $targetResourceRow.find('select option:selected').text()

    _setVendorName(index, _$currentVendor.find('h3').text())
    _setQuality(index, selectedOption)
    _setUnitPrice(index, $targetResourceRow.data(selectedOption + '-unit-price'))
    _updatePrices(index, _$container.data('repository-id'), $targetResourceRow.data('language-code'))
  }

  function _onClickOrderResourceSubmitBtn () {
    var message = 'We placed an order for translation'

    var data = {
      vendor: _$currentVendor.find('h3').text(),
      total_segments_count: _getTotalSegmentsCount(),
      resources: []
    }

    var $resources = $('.js_vendor-order-real-resource-row[data-disabled=false]')
    $.each($resources, function (index, resource) {
      var $resource = $(resource)
      data.resources.push({
        language_code: $resource.data('language-code'),
        quality: $resource.find('td[data-property=quality]').data('value')
      })
    })

    if ($('#card-element').length > 0) {
      _stripe.createToken(_stripeCard).then(function (result) {
        if (result.error) {
          var errorElement = document.getElementById('card-errors')
          errorElement.textContent = result.error.message
        } else {
          data['stripeToken'] = result.token.id
          _placeAnOrder(data, message)
        }
      })
    } else {
      _placeAnOrder(data, message)
    }
    woopra.track('order_confirmed')
  }

  function _placeAnOrder (data, message) {
    $.ajax({
      method: 'POST',
      url: '/api/repo/' + _$container.data('repository-id') + '/order',
      data: JSON.stringify(data || {}),
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      success: function () {
        _$modal.modal('hide')
        window.location.assign(_$container.data('redirect-to') + '?message=' + message)
      },
      error: function (jqXHR, textStatus, errorThrown) {
        _$modalErrorMessage.text(jqXHR.responseJSON.error)
        _$modalErrorMessageContainer.show()
      }
    })
  }

  function _updatePrices (index, repositoryId, lc) {
    _$jsLoader.show()
    var $row = _$container.find('.js_vendor-target-resource-row[data-id=' + index + ']')
    var unitCount = $row.data('unit-count')
    var unitPrice = $row.data($row.find('select > option:selected').val() + '-unit-price')
    var spreadRatio = 1.2

    if ($row.data('unit-count')) {
      _setUnitCountAndPriceAndUpdateStatus(index, unitPrice, unitCount, spreadRatio)
      _$jsLoader.hide()
    } else if (_$container.find('.js_vendor-order-resource-row[data-id=' + index + '][data-disabled=false]').length > 0) {
      $.ajax({
        url: '/api/repo/' + repositoryId + '/' + lc + '/untranslated_unit_count',
        success: function (data) {
          var count = data.repository.unit_count
          $row.attr('data-unit-count', count)
          _setUnitCountAndPriceAndUpdateStatus(index, unitPrice, count, spreadRatio)
          _$jsLoader.hide()
        }
      })
    }
  }

  function _setUnitCountAndPriceAndUpdateStatus (index, unitPrice, count, spreadRatio) {
    _setUnitCount(index, count)
    _setPrice(index, unitPrice * count * spreadRatio)
    _setApproximatePrice()
    _checkAndUpdateOrderStatus()
  }

  function _setVendorName (index, name) {
    _setValue('name', index, name)
  }

  function _setQuality (index, quality) {
    _setValue('quality', index, quality)
  }

  function _setUnitPrice (index, unitPrice) {
    _setValue('unit-price', index, _randOff(unitPrice), { unit: { name: '$' } })
  }

  function _setUnitCount (index, unitCount) {
    _setValue('unit-count', index, unitCount)
  }

  function _setPrice (index, price) {
    _setValue('price', index, _randOff(price), { unit: { name: '$' } })
  }

  function _setApproximatePrice () {
    var approximatePrice = 0
    var $list = $('.js_vendor-order-resource-countable-row[data-disabled=false] td[data-property=price]')
    $.each($list, function () { approximatePrice += +this.getAttribute('data-value') })
    _$orderResourceApproximatePrice.attr('data-value', _randOff(approximatePrice))
    _$orderResourceApproximatePrice.text('$' + _randOff(approximatePrice))
  }

  function _setValue (property, index, value, opt) {
    var $orderResourceRow = $('.js_vendor-order-resource-row[data-id=' + index + ']')
    var $orderResourceCell = $orderResourceRow.find('td[data-property=' + property + ']')
    var option = opt || {}
    $orderResourceCell.attr('data-value', value)
    $orderResourceCell.text((typeof (option) === 'object' && option.unit && option.unit.name) ? option.unit.name + value : value)
  }

  function _getTotalSegmentsCount () {
    var totalSegmentsCount = 0
    var $list = $('.js-vendor-order-resource-countable-row[data-disabled=false] td[data-property=unit-count]')

    $.each($list, function () { totalSegmentsCount += +this.getAttribute('data-value') })
    return totalSegmentsCount
  }

  function _randOff (value) {
    return Math.floor(value * 100) / 100
  }

  function _buildStripeElement () {
    var cardElement = $('#card-element')
    _stripe = Stripe(cardElement.data('key'))
    var elements = _stripe.elements()
    _stripeCard = elements.create('card')
    _stripeCard.mount('#card-element')
  }
}

export default Vendor;
