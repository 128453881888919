var SelectUser = function () {

  initialize.apply(this, arguments)

  function initialize ($select) {
    $select.select2({
      ajax: {
        url: '/api/users',
        type: 'GET',
        data: function (params) {
          var query = {
            q: params.term,
            limit: 15
          }

          return query;
        },
        processResults: function (data, _params) {
          return { results: data.users };
        }
      },
      multiple: true,
      width: '100%',
      placeholder: 'Optional',
      templateResult: formatUser,
      templateSelection: formatUserSelection
    })
  }


  function formatUser (user) {
    if (user.loading) {
      return user.text;
    }

    var $container = $(
      "<div class='select2-result-user clearfix'>" +
        "<div class='select2-result-user__avatar'><img src='" + user.avatar_urls.small + "' /></div>" +
        "<div class='select2-result-user__username'></div>" +
      "</div>"
    );

    $container.find(".select2-result-user__username").text(user.username);

    return $container;
  }

  function formatUserSelection (user) {
    $(user.element).attr('data-username', user.username)
    return user.username;
  }
}

export default SelectUser;
