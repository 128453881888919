$(function () {
  var $container = $('.commit')
  if ($container.length === 0) {
    return
  }

  var $buttons = $container.find('.commit-button')

  var $form = $container.find('.commit-form')
  var $formCommit = $form.find('.commit-form-commit')
  var $formPullRequest = $form.find('.commit-form-pull-request')

  var $dropdownToggle = $container.find('.commit-button-dropdown-toggle')
  var $dropdownMenu = $container.find('.commit-button-dropdown-menu')
  var $commitBtn = $container.find('.commit-button-commit')
  var $pullRequestBtn = $container.find('.commit-button-pull-request')
  var $commitCancelBtn = $container.find('.commit-cancel-button')
  var $commentForm = $('.review-request-comment')
  var menuShowing = false

  function changeCommitType (type) {
    if (type === 'commit') {
      $commitBtn.show()
      $pullRequestBtn.hide()
    } else {
      $commitBtn.hide()
      $pullRequestBtn.show()
    }
  }

  function toggleMenu () {
    menuShowing = !menuShowing
    if (menuShowing) {
      $dropdownMenu.css({ display: 'block' })
    } else {
      $dropdownMenu.css({ display: 'none' })
    }
  }

  function toggleForm (type) {
    if (type === 'commit') {
      $buttons.hide()
      $form.show()
      $formCommit.show()
      $formPullRequest.hide()
    } else if (type === 'pull-request') {
      $buttons.hide()
      $form.show()
      $formCommit.hide()
      $formPullRequest.show()
    } else {
      $buttons.show()
      $form.hide()
    }
  }

  $dropdownToggle.click(function (e) {
    toggleMenu()
  })

  $dropdownMenu.find('a').click(function (e) {
    e.preventDefault()
    var commitType = $(e.currentTarget).data('commitType')
    changeCommitType(commitType)
    toggleMenu()
  })

  $commitBtn.click(function (e) {
    e.preventDefault()
    toggleForm('commit')
  })

  $pullRequestBtn.click(function (e) {
    e.preventDefault()
    toggleForm('pull-request')
    $commentForm.hide()
  })

  $commitCancelBtn.click(function (e) {
    e.preventDefault()
    toggleForm()
    $commentForm.show()
  })

  toggleForm()
  changeCommitType('pull-request')
  $container.removeClass('hidden')
})
