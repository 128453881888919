$(function () {
  $('#select-badge-type')
    .change(function () {
      var langCode = $('#select-badge-type option:selected').val()
      var repoId = window.location.pathname.match(/\d+/)[0]

      setSVG(repoId, langCode)
    })

  $('#select-badge-code')
    .change(function () {
      setBadgeCode()
    })

  $('#js-copy-button')
    .click(function () {
      $('#js-badge-textarea').select()
      document.execCommand('copy')
    })

  $('#select-badge-type').val('whole_project').trigger('change')

  function setSVG (repoId, langCode) {
    $('#js-svg-badge').attr('src', '/repo/' + repoId + '/' + langCode + '/badge.svg')
    setBadgeCode()
  }

  function setBadgeCode () {
    var percent = $('#js-percents').text()
    var codeType = $('#select-badge-code option:selected').val()
    var repoId = window.location.pathname.match(/\d+/)[0]
    var badgeType = $('#select-badge-type option:selected').val()
    var url = `https://${window.location.hostname}/repo/${repoId}/${badgeType}`
    var badgeTypeForUrl = badgeType === 'whole_project' ? '' : `/${badgeType}`
    var repoUrl = `https://${window.location.hostname}/repo/${repoId}${badgeTypeForUrl}`

    switch (codeType) {
      case 'html':
        $('#js-badge-textarea').text('<a href="' + repoUrl + '?utm_source=badge"> <img src="' + url + '/badge.svg" /> </a>')
        break
      case 'image':
        $('#js-badge-textarea').text(url + '/badge.svg')
        break
      case 'bb':
        $('#js-badge-textarea').text('[url=' + repoUrl + '?utm_source=badge][img]' + url + ' /badge.svg[/img][/url]')
        break
      case 'markdown':
        $('#js-badge-textarea').text('[![gitlocalized ' + percent + '](' + url + '/badge.svg)](' + repoUrl + '?utm_source=badge)')
        break
    }
  }
})
