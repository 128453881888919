/* global $ */
var SyncRepository = function () { // eslint-disable-line no-unused-vars
  var _$container
  var _$button
  var _id
  var checkSyncStatusTimer

  initialize.apply(this, arguments)

  function initialize ($container) {
    if ($container.length === 0) return false

    _$container = $container
    _$button = _$container.find('.js_sync-button')
    _id = _$button.data('id')

    _checkSyncingStatus()

    _$button.on('click', _onClick)
  }

  function _checkSyncingStatus () {
    if (_$button.data('syncing')) {
      _disableControls()
      _setCheckSyncStatusTimer(_id)
    }
  }

  function _disableControls () {
    _$button.prop('disabled', true)
    $('#repository-upload').prop('hidden', false)
    $('.submit-path-rules-settings').prop('disabled', true)
    $('.delete-lang').prop('disabled', true)
  }

  function _enableControls () {
    _$button.prop('disabled', false)
    $('#repository-upload').prop('hidden', true)
    $('.submit-path-rules-settings').prop('disabled', false)
    $('.delete-lang').prop('disabled', false)
  }

  function _showSyncErrorModal (message) {
    $('#popup-sync-failed-header').text('Sync failed')
    if (message) {
      $('#popup-sync-failed-paragraph').text(message)
    }
    $('#popup-sync-failed').modal('show')
  }

  function _onClick () {
    _disableControls()

    $.ajax({
      url: `/api/repo/${_id}/sync`,
      method: 'post',
      success: function (data) {
        if (data.status === 'success') {
          _setCheckSyncStatusTimer(_id)
        } else {
          const message = (data.status === 'not_found')
            ? `We can't find your GitHub App installation, please, check it.` : null
          _showSyncErrorModal(message)
        }
      },
      error: function (data) {
        _showSyncErrorModal()
        _enableControls()
      }
    })
  }

  function _setCheckSyncStatusTimer (repoId) {
    if (!checkSyncStatusTimer) {
      checkSyncStatusTimer = setInterval(function () {
        $.ajax({
          url: `/api/repo/${repoId}/is_syncing.json`,
          method: 'get',
          dataType: 'json',
          success: function (data) {
            if (!data.is_syncing) {
              _updateLastCrawledAt(data)
              _enableControls()

              clearInterval(checkSyncStatusTimer)
              checkSyncStatusTimer = null
            }
          }
        })
      }, 3000)
    }
  }

  function _updateLastCrawledAt (data) {
    _$container.find('.js_sync-last-crawled-at').text(`Synced ${data.last_synced}`)
  }
}

export default SyncRepository;
