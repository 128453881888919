import { addNameplate } from './nameplate_methods';

$(() => {
  $('[data-behavior~=language_characters_result]').each((_i, current) => {
    var statistic = {
      total_chars: current.getAttribute('total-chars-data'),
      translated_chars: current.getAttribute('translated-chars-data'),
      untranslated_chars: current.getAttribute('untranslated-chars-data'),
      approved_chars: current.getAttribute('approved-chars-data'),
      unapproved_chars: current.getAttribute('unapproved-chars-data'),
      total_words: current.getAttribute('total-words-data'),
      translated_words: current.getAttribute('translated-words-data'),
      untranslated_words: current.getAttribute('untranslated-words-data'),
      approved_words: current.getAttribute('approved-words-data'),
      unapproved_words: current.getAttribute('unapproved-words-data')
    }

    addNameplate(current, statistic);
  })
})


