$(() => {
  function showFlashError (message) {
    var htmlBlock = '<div class="alert alert-danger mtm d-flex" role="alert"><div>' + message + '</div><button type="button" class="close ml-auto" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
    $('.flash-error-container').html(htmlBlock)
  }

  function removeFlashError () {
    $('.flash-error-container').empty()
  }
  window.showFlashError = showFlashError
  window.removeFlashError = removeFlashError
})
