$(function () {
  var AddUserRole = function () {
    var _$container
    var _$result
    var _$input
    var _currentText
    var _$languageSelect
    var _$roleSelect
    var _$userIdHidden
    var _MIN_TEXT_LENGTH = 1

    initialize.apply(this, arguments)

    function initialize ($container) {
      _$container = $container
      _$submitBtn = _$container.find('.btn-locki')
      _$result = _$container.find('.js_user-search-result')
      _$input = _$container.find('.js_user-search-input')
      _$languageSelect = _$container.find('.js_add-user-role-language')
      _$roleSelect = _$container.find('.js_add-user-role-role')
      _$userIdHidden = _$container.find('.js_add-user-id')

      _$input.on('keyup', _onChange)
      _$roleSelect.on('change', _onRoleChange)
      _$result.on('click', _onClick)
      _$submitBtn.on('click', _onSubmit)
    }

    function _onChange (e) {
      var text = e.target.value
      if (text !== _currentText && text.length >= _MIN_TEXT_LENGTH) {
        _search(text)
      } else if (text.length < _MIN_TEXT_LENGTH) {
        _$result.html('')
      }
      _currentText = text
      _$userIdHidden.val('')
    }

    function _onClick (e) {
      var $li = $(e.target).closest('li')
      var userId = $li.data('user-id')
      var username = $li.data('username')

      _$input.val(username)
      _$userIdHidden.val(userId)
      _$result.html('')
    }

    function _onRoleChange (e) {
      var currentRole = _$roleSelect.val()
      if ($.inArray(currentRole, ['moderator', 'moderator_vendor']) === -1) {
        _$languageSelect.css('display', 'none')
      } else {
        _$languageSelect.css('display', '')
      }
    }

    function _search (query) {
      $.ajax({
        url: '/api/users',
        type: 'GET',
        data: { q: query, limit: 15 },
        success: function (data) {
          _updateUI(data.users)
        }
      })
    }

    function _updateUI (users) {
      if (users.length > 0) {
        _$result.html(renderUserList(users))
      } else {
        _$result.html('')
      }
    }

    function renderUserList(users) {
      var div = document.createElement('div');
      div.classList.add('user-search-result');

      var ul = document.createElement('ul');
      ul.classList.add('result-items');

      for(var i = 0; i < users.length; i++) {
        ul.appendChild(renderUserLi(users[i]));
      }
      div.appendChild(ul);

      return div;
    }

    function renderUserLi(user) {
      var li = document.createElement('li');
      li.setAttribute('data-user-id', user.id);
      li.setAttribute('data-username', user.username);

      var div = document.createElement('div');
      div.classList.add('image');

      var img = document.createElement('img');
      img.setAttribute('src', user.avatar_urls.small);

      div.appendChild(img);
      li.appendChild(div);

      div = document.createElement('div');
      div.classList.add('name');

      div.appendChild(document.createTextNode(user.username));

      li.appendChild(div);

      return li;
    }

    function _onSubmit (e) {
      if (!_$input.val()) {
        e.preventDefault()
        e.stopImmediatePropagation()
        showFlashError('Please select a username of your team member from the drop-down list')
      } else if (!_$roleSelect.val()) {
        e.preventDefault()
        e.stopImmediatePropagation()
        showFlashError('Please assign a role to this team member - this field cannot be blank')
      } else {
        removeFlashError()
      }
    }
  }

  AddUserRole($('.js_add-user-role'))
})
