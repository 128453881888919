$(() => {
  var downloadButton = $('.js-download-translation-memory')
  var popup = $('#popup-tmx')

  downloadButton.on('click', function() {
    $.ajax({
      url: '/api/memories/' + downloadButton.data('id') + '/download_tmx'
    });
    popup.modal('show')
  });
});

