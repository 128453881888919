$(() => {
  $('#select-all-review-requests-checkbox').on('change', function () {
    $('input[type=checkbox][data-behaviour="select-all-review-requests-target"]').prop('checked', this.checked)
    const labelText = this.checked ? 'Unselect All' : 'Select All'

    const anyChecked = $('input[type=checkbox][data-behaviour="select-all-review-requests-target"]')
      .toArray()
      .some((element) => element.checked)

    if (anyChecked) {
      $('#send-pull-request-button').removeAttr('disabled')
      $('#close-pull-request-button').removeAttr('disabled')
      $('[data-behavior~=download-button]').removeAttr('disabled')

    } else {
      $('#send-pull-request-button').attr('disabled', 'disabled')
      $('#close-pull-request-button').attr('disabled', 'disabled')
      $('[data-behavior~=download-button]').attr('disabled')
    }
    $('#select-all-label').text(labelText)
  })

  $('input[type=checkbox][data-behaviour="select-all-review-requests-target"]').on('change', function () {
    const allCheckboxesArray = $('input[type=checkbox][data-behaviour="select-all-review-requests-target"]').toArray()
    if (allCheckboxesArray.every((element) => element.checked)) {
      $('#select-all-review-requests-checkbox').prop('checked', true)
      $('#select-all-label').text('Unselect All')
    } else if (allCheckboxesArray.every((element) => !element.checked)) {
      $('#select-all-review-requests-checkbox').prop('checked', false)
      $('#select-all-label').text('Select All')
    } else {
      $('#select-all-review-requests-checkbox').prop('checked', false)
      $('#select-all-label').text('Select All')
    }

    const anyChecked = $('input[type=checkbox][data-behaviour="select-all-review-requests-target"]')
      .toArray()
      .some((element) => element.checked)
    if (anyChecked) {
      $('#send-pull-request-button').prop('disabled', false)
      $('#close-pull-request-button').prop('disabled', false)
      $('[data-behavior~=download-button]').prop('disabled', false)
    } else {
      $('#send-pull-request-button').prop('disabled', true)
      $('#close-pull-request-button').prop('disabled', true)
      $('[data-behavior~=download-button]').prop('disabled', true)
    }
  })

  $('#send-pull-request-button').on('click', function () {
    $('#send-pull-request-modal').modal('show')
    const idsContainer = $('#selected-review-request-ids-container')
    const allCheckboxesArray = $('input[type=checkbox][data-behaviour="select-all-review-requests-target"]')
      .toArray()
    const selectedCheckboxesArray = allCheckboxesArray.filter(element => element.checked)
    idsContainer.empty()
    selectedCheckboxesArray.forEach(function (element) {
      $('<input>').attr({ type: 'hidden', name: 'pull_request[selected_review_request_ids][]', value: element.value }).appendTo(idsContainer)
    })
  })

  $('#close-pull-request-button').on('click', function () {
    const idsContainer = $('#selected-review-request-ids-container')
    const allCheckboxesArray = $('input[type=checkbox][data-behaviour="select-all-review-requests-target"]')
      .toArray()
    const selectedCheckboxesArray = allCheckboxesArray.filter(element => element.checked)
    idsContainer.empty()

    var repositoryId = window.location.pathname.split('/')[2]
    var languageCode = window.location.pathname.split('/')[3]
    $.ajax({
      url: '/api/repo/' + repositoryId + '/' + languageCode + '/review/close',
      method: 'post',
      data: { review_requests_ids: selectedCheckboxesArray.map(function (elem) { return elem.value }) },
      success: () => {
        window.location.reload()
      }
    })
  })

  $('[data-behavior~=download-button]').on('click', function () {
    const idsContainer = $('#selected-review-request-ids-container')
    const allCheckboxesArray = $('input[type=checkbox][data-behaviour="select-all-review-requests-target"]')
      .toArray()
    const selectedCheckboxesArray = allCheckboxesArray.filter(element => element.checked)
    const resourceIds = selectedCheckboxesArray.map(function (elem) { return elem.getAttribute('data-resource-id') })
    idsContainer.empty()

    var repositoryId = window.location.pathname.split('/')[2]

    $.ajax({
      url: '/api/repo/' + repositoryId + '/download_completed_resources',
      method: 'post',
      data: { resource_ids: resourceIds },
      success: (data) => {
        if (data.status === 'success') {
          checkDownloadResourcesStatus(repositoryId, resourceIds)
        }
      },
      error: function () {
        $('#download-resources-failed').removeClass('hidden')
      }
    })
  })

  function checkDownloadResourcesStatus (repositoryId, resourceIds) {
    const $spinner = $('#download-resources-spinner')
    const $alert = $('#download-resources-failed')
    const $downloadLink = $('#download-resources-link')
    const $rrRows = $('li.review-request-row')
    $spinner.removeClass('hidden')

    const repoDownloadResourcesTimer = setInterval(function () {
      $.ajax({
        url: '/api/repo/' + repositoryId + '/download_completed_resources_status',
        method: 'get',
        data: { resource_ids: resourceIds },
        success: function (data) {
          if (data.status === 'failed') {
            $spinner.addClass('hidden')
            $alert.removeClass('hidden')
            clearInterval(repoDownloadResourcesTimer)
          } else if (data.status === 'in_progress') {
            $spinner.removeClass('hidden')
          } else if (data.status === 'success') {
            $spinner.addClass('hidden')
            $downloadLink.attr('href', data.download_url)
            $downloadLink.removeClass('hidden')
            clearInterval(repoDownloadResourcesTimer)

            var successIds = data.success_ids
            var failedIds = data.failed_ids

            $rrRows.each(function (_index, rrRow) {
              var resourceId = parseInt(
                $(rrRow).find('input[type=checkbox][data-behaviour="select-all-review-requests-target"]')[0].getAttribute('data-resource-id')
              )
              if (successIds.includes(resourceId)) {
                rrRow.style.backgroundColor = '#89bb58'
                $(rrRow).find('span.success-download').removeClass('hidden')
                $(rrRow).find('span.fail-download').addClass('hidden')
              } else if (failedIds.includes(resourceId)) {
                rrRow.style.backgroundColor = '#da7b52'
                $(rrRow).find('span.success-download').addClass('hidden')
                $(rrRow).find('span.fail-download').removeClass('hidden')
              }
            })
          }
        },
        error: function () {
          $spinner.addClass('hidden')
          $alert.removeClass('hidden')
        }
      })
    }, 5000)
  }

  $('.review-requests-comments-input').keyup(function () {
    $('.js-comment-submit-btn').prop('disabled', this.value === '')
  })
})
